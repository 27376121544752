// Generic imports
import React from "react"

// Other components imports
import Order from "components/Payment/DetailPaymentPlus/Order"
import Price from "components/Payment/DetailPayment/Price"

// Redux imports

// Static imports
import { Styles } from "./style"
import belinda from "images/classes/belinda.png"
import back from "images/payment/back.png"
import edit from "images/payment/Edit.png"
import next from "images/payment/next.png"

// Utils imports
export default function DetailPayment() {
  return (
    <Styles>
      <div className="board">
        <div className="header-title">
          <button className="back-style">
            <img src={back} />
          </button>
          <div className="page-title">Rincian Belanja</div>
        </div>
        <div style={{ paddingTop: "80px" }}>
          <Price total="1111111111" id="000000000000" />
        </div>
        <div style={{ marginTop: "55px" }}>
          <div className="sub-title">PESANAN</div>
          <Order
            name="Nihonggo Club"
            owner="Fatimah Syakura"
            thumbnail={belinda}
          />
        </div>
        <button className="edit">
          <img src={edit} /> EDIT CHECKOUT
        </button>
        <button className="footer-button">
          <div className="text-next">
            LANJUT <img className="icon-next" src={next} />
          </div>
        </button>
      </div>
    </Styles>
  )
}
