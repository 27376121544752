// Generic imports
import React from "react"

// Other components imports
import Layout from "components/layout"
// import Price from "components/Payment/Price"
// import DetailPayment from "components/Payment/DetailPayment"
// import Order from "components/Payment/DetailPaymentPlus/Order"
// import DetailClass from "components/Payment/DetailClass"
// import PricePackage from "components/Payment/DetailPaymentPlus/PricePackage"
import DetailPaymentPlus from "components/Payment/DetailPaymentPlus"
import SEO from "components/seo"

// Redux imports

// Static imports
// import belinda from "images/classes/belinda.png"
// import seikhlasnya from "images/classes-card/tamanClassSeikhlasnya.png"
// Utils imports

const Test = () => (
  <Layout>
    <SEO title="Test" />
    {/* <Price total="1111111111" id="000000000000" /> */}
    {/* <Order name="Nihonggo Club" owner="Fatimah Syakura" thumbnail={belinda} /> */}
    {/* <DetailClass teacher="Fatimah Syakura" schedule="Januari" location="Google Meets" /> */}
    {/* <DetailPayment /> */}
    {/* <PricePackage color="gold" judul="PAKET BEREMPAT" harga="250000" /> */}
    <DetailPaymentPlus />
  </Layout>
)

export default Test
