// Generic imports
import styled from "styled-components"

// Static imports

export const Styles = styled.div`
  button {
    border: 0;
    background-color: hsla(290, 60%, 100%, 0.3);
  }
  .board {
    position: relative;
    width: 375px;
    height: 640px;
    background: #fafafa;
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    margin: auto;
  }

  .header-title {
    position: absolute;
    width: 375px;
    height: 60px;
    left: 0px;
    top: 0px;
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .page-title {
    position: absolute;
    right: 4.27%;
    top: 30%;
    bottom: 30%;
    text-align: right;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: right;
    letter-spacing: 0.15px;
    color: #52575c;
  }

  .back-style {
    position: absolute;
    left: 5%;
    right: 91.47%;
    top: 30%;
    bottom: 30%;
  }

  .sub-title {
    position: absolute;
    left: 12px;
    top: 217px;
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 1.5px;
    color: #a0a4a8;
  }

  .edit {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 28px;
    font-family: Avenir;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 19px;
    display: block;
    align-items: center;
    letter-spacing: 1.25px;
    color: #51b848;
    text-align: center;
  }

  .footer-button {
    position: absolute;
    width: 375px;
    height: 48px;
    bottom: 0;
    background: #51b848;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .text-next {
    position: absolute;
    width: 320px;
    left: 7.47%;
    right: 76.53%;
    top: 29.17%;
    bottom: 31.25%;
    font-family: Avenir;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #ffffff;
    text-align: left;
  }

  .icon-next {
    position: absolute;
    right: 0%;
    top: 20%;
    bottom: 0%;
  }
`
