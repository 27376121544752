// Generic imports
import styled from "styled-components"

// Static imports

export const Styles = styled.div`
  .container {
    position: relative;
    width: 351px;
    height: 103px;
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  .text {
    position: absolute;
    width: 54px;
    height: 19px;
    left: 20px;
    top: 17px;
    font-family: Avenir;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.5px;
    color: #52575c;
  }

  .price {
    display: flex;
    float: right;
    flex-direction: row;
    align-items: right;
    padding: 0px;
    position: absolute;
    max-width: 250px;
    height: 42px;
    right: 16px;
    top: 12px;
  }

  .price-currency {
    position: static;
    float: right;
    width: 22px;
    height: 31px;
    right: auto;
    top: 5.5px;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20p;
    text-align: right;
    align-item: right;
    letter-spacing: 0.15px;
    color: #52575c;
    flex: none;
    order: 0;
    flexgrow: 0;
    margin: 0px 2px;
  }

  .price-nominal {
    position: static;
    min-width: 125px;
    height: 42px;
    right: 0px;
    top: 0px;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 42px;
    text-align: right;
    letter-spacing: 0.25px;
    color: #333333;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 2px;
  }

  .box {
    position: absolute;
    height: 30px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: rgba(81, 184, 72, 0.1);
  }

  .box-text {
    position: absolute;
    width: 52px;
    height: 19px;
    left: 20px;
    bottom: 4px;
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.4px;
    color: rgba(81, 184, 72, 0.75);
  }

  .box-id {
    position: absolute;
    min-width: 98px;
    height: 16px;
    right: 16px;
    bottom: 7px;
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    letter-spacing: 0.4px;
    color: #52575c;
  }
`
