// Generic imports
import React from "react"

// Other components imports

// Redux imports

// Static imports
import { Styles } from "./style"

// Utils imports

export default function Price({ total, id }) {
  return (
    <Styles>
      <div className="container">
        <div className="text">Jumlah</div>
        <div className="price">
          <span className="price-currency">Rp</span>
          <div className="price-nominal">{total}</div>
        </div>
        <div className="box">
          <div className="box-text">Order ID</div>
          <div className="box-id">{id}</div>
        </div>
      </div>
    </Styles>
  )
}
