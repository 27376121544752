// Generic imports
import styled from "styled-components"

// Static imports

export const Styles = styled.div`
  .card {
    position: relative;
    width: 351px;
    height: 288px;
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin: auto;
  }

  .header {
    position: absolute;
    width: 351px;
    min-height: 52px;
    left: 0px;
    top: 0px;
    background: rgba(81, 184, 72, 0.1);
  }

  .thumb {
    position: static;
    width: 52px;
    height: 52px;
    left: 0px;
    top: 0px;
  }

  img {
    object-fit: cover;
  }

  .class-name {
    position: static;
    height: 20px;
    max-width: 225px;
    padding-left: 10px;
    top: 0px;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: #333333;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 4px;
  }

  .label {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px;
    position: static;
    height: 16px;
    left: 117px;
    top: 2px;
  }

  .class-by {
    position: absolute;
    height: 16px;
    left: 67px;
    top: 32px;
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.4px;
    color: #000000;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    position: absolute;
    height: 20px;
    left: 67px;
    top: 10px;
  }
`
