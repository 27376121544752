// Generic imports
import React from "react"

// Other components imports

// Redux imports

// Static imports
import { Styles } from "./style"
import plus from "images/classes-card/tamanClassPlus.png"

// Utils imports

export default function Order({ name, owner, thumbnail }) {
  return (
    <Styles>
      <div className="card">
        <div className="header">
          <div>
            <img className="thumb" src={thumbnail} />
          </div>
          <div className="row">
            <div className="class-name">{name}</div>
            <div className="label">
              <img src={plus} />
            </div>
          </div>
          <div className="class-by ">
            by <span style={{ color: "#51B848" }}>{owner}</span>
          </div>
        </div>
      </div>
    </Styles>
  )
}
